<template>
    <setting-layout :title="$t('pages.profile.info.title')">
        <v-container class="mt-0 pt-1 pl-10 pr-10">
            <v-row justify="center" class="ml-5">
                <div
                    class="image-container"
                    :class="{ 'story-exist': $auth.user().isExistStory }"
                    @click="handleChangeAvatar"
                >
                    <profile-image
                        :src="model.profileImage"
                        :username="user.name"
                        :size="95"
                        :font-size="31"
                    />
                </div>
                <div class="follow-button">
                    <app-icon
                        icon="write"
                        color="red"
                        size="16"
                        @click="handleChangeAvatar"
                    />
                </div>
            </v-row>
            <v-row class="mt-6">
                <v-textarea
                    color="rgba(237,237,237, .1)"
                    background-color="rgba(237,237,237, .1)"
                    hide-details
                    no-resize
                    solo
                    :placeholder="$t('pages.profile.info.type_your_bio')"
                    rows="4"
                    v-model="model.bio"
                >
                </v-textarea>
            </v-row>
            <v-row class="mt-6">
                <text-field
                    v-model="model.amazon"
                    :placeholder="$t('pages.profile.info.amazon_url')"
                >
                    <template v-slot:prepend-inner>
                        <app-icon
                            icon="amazon"
                            size="20"
                            class="mr-2"
                        ></app-icon>
                    </template>
                </text-field>
            </v-row>
            <v-row class="mt-6">
                <text-field
                    v-model="model.instagram"
                    :placeholder="$t('pages.profile.info.instagram_url')"
                >
                    <template v-slot:prepend-inner>
                        <v-icon size="20" class="mr-2">mdi-instagram</v-icon>
                    </template>
                </text-field>
            </v-row>
            <v-row class="mt-6">
                <text-field
                    v-model="model.twitter"
                    :placeholder="$t('pages.profile.info.twitter_url')"
                >
                    <template v-slot:prepend-inner>
                        <img
                            :src="require('@/assets/images/logo/x.png')"
                            alt="x"
                            title="x"
                            width="20px"
                            class="mr-2"
                        />
                    </template>
                </text-field>
            </v-row>
            <v-row class="mt-8 pr-2 pl-2">
                {{ $t("pages.profile.info.my_age") }}
            </v-row>
            <v-row align="baseline" class="pr-2 pl-2 mt-1">
                18
                <v-slider
                    v-model="model.age"
                    thumb-label
                    min="18"
                    max="100"
                    :class="{ 'cursor-slider': true }"
                ></v-slider>
                100
            </v-row>
            <v-row class="pr-2 pl-2">
                {{ $t("pages.profile.info.i_am") }}
            </v-row>
            <v-row justify="center">
                <v-col v-for="(item, i) in genders" :key="i">
                    <div
                        :class="{
                            'select-gender': true,
                            'pa-2': true,
                            active: item.icon === model.gender,
                        }"
                        @click="model.gender = item.icon"
                    >
                        <app-icon
                            :icon="item.icon"
                            class="gender-icon"
                            size="16"
                        />
                        <p>{{ item.text }}</p>
                    </div>
                </v-col>
            </v-row>

            <v-row class="pr-2 pl-2 mt-4">
                {{ $t("pages.profile.info.categories") }}
            </v-row>
            <v-row>
                <user-categories :user-gender="model.gender" :initial-selected-ids="preSelectedCategoryIds" @update:selectedCategoryIds="handleSelectedCategories" />
            </v-row>

            <v-row align="center" justify="center" class="mt-10 ml-5 mr-5">
                <gradient-button
                    block
                    @click="save"
                    :loading="$store.state.buttonsLoading.infoBtn"
                >
                    {{ $t("dialogs.save") }}
                </gradient-button>
            </v-row>
        </v-container>
        <app-transition>
            <record-screen
                v-if="step === 0"
                :disable-video="true"
                :disable-audio="true"
                key="screen"
                :is-front-camera="isFrontCamera"
                @change-camera="isFrontCamera = $event"
            />
            <view-avatar
                v-if="step === 1"
                key="result"
                @set-image="handleSetImage"
            />
        </app-transition>
    </setting-layout>
</template>

<script>
import SettingLayout from "@/layouts/app/profile/SettingLayout";
import { mapActions, mapMutations, mapState } from "vuex";
import TextField from "@/components/app/form/TextField";
import GradientButton from "@/components/app/button/GradientButton";
import ProfileImage from "@/components/app/notifications/ProfileImage";
import RecordScreen from "@/views/app/postingView/RecordScreen";
import ViewAvatar from "@/views/app/postingView/ViewAvatarScreen";
import Checkboxes from "@/components/app/posting/BeforePostingBlock.vue";
import UserCategories from "@/components/app/profile/UserCategories";

export default {
    name: "Info",
    data() {
        return {
            model: {
                bio: "",
                amazon: "",
                age: 18,
                gender: "",
                profileImage: "",
                categories: [],
            },
            file: new Blob(),
            isFrontCamera: false,
            preSelectedCategoryIds: {},
        };
    },
    methods: {
        ...mapActions([
            "setProfileInfo",
            "getCurrentUserData",
            "getOwnMainUserData",
        ]),
        ...mapMutations(["setStep"]),
        setModel(value) {
            this.model = {
                bio: value.bio || "",
                amazon: value.amazon || "",
                twitter: value.twitter || "",
                instagram: value.instagram || "",
                age: value.age || 18,
                gender: value.gender || "",
                profileImage: value.profileImage || "",
                changeAvatar: false,
            };

            if (value.categories) {                
                this.preSelectedCategoryIds = value.categories.reduce((acc, category) => {
                    acc[category.parent_id] = category.id;
                    return acc;
                }, {});
            }
        },
        save() {
            this.setProfileInfo({ model: this.model }).then(() => {
                this.getCurrentUserData().then((res) => {
                    this.setModel(res.data);
                    this.getOwnMainUserData(res.data);
                });
            });
        },
        handleChangeAvatar() {
            this.setStep(0);
        },
        handleSetImage() {
            this.model.changeAvatar = true;
            this.model.profileImage = this.imageDataUrl;
        },
        handleSelectedCategories(selectedIds) {
            this.model.categories = Object.values(selectedIds);
        },
    },
    watch: {
        user(value) {
            this.setModel(value);
        },
        step(value) {
            if ([0, 1].includes(value)) {
                document.getElementsByTagName("html")[0].style.overflow =
                    "hidden";
            } else {
                document.getElementsByTagName("html")[0].style.overflow =
                    "scroll";
            }
        },
    },
    computed: {
        ...mapState({
            user: (state) => state.user.user.data,
            step: (state) => state.story.isShowCameraScreen.step,
            imageDataUrl: (state) => state.story.imageDataUrl,
        }),
        genders() {
            return [
                { text: this.$t("pages.profile.info.male"), icon: "male" },
                { text: this.$t("pages.profile.info.female"), icon: "female" },
                {
                    text: this.$t("pages.profile.info.transgender"),
                    icon: "transgender",
                },
            ];
        },
    },
    components: {
        Checkboxes,
        GradientButton,
        TextField,
        SettingLayout,
        RecordScreen,
        ViewAvatar,
        ProfileImage,
        UserCategories,
    },
    mounted() {
        this.setModel(this.user);

        this.$mixpanel.identifyUser(this.user.email);
        this.$mixpanel.pageViewed('Info');
    },
};
</script>

<style scoped lang="scss">
@import "@/sass/modules/_variables";

.profile-image {
    height: 95px;
    width: 95px;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-color: whitesmoke;
    background-size: cover;
    object-fit: cover;
}

.image-container {
    border-radius: 50%;
    padding: 4px;
}

.story-exist {
    background: $accent-background;

    .profile-image {
        border: 3px solid black;
        object-fit: cover;
    }
}

.name {
    font-size: 1.5em;
}

.counters {
    font-size: 1.5em;
}

.linked {
    text-decoration: none;
}

$size-button: 34px;
.follow-button {
    height: $size-button;
    width: $size-button;
    background: white;
    text-align: center;
    text-decoration: none;
    color: #fe4811;
    border-radius: 50%;
    border: 3px solid black;
    padding-top: 0;
    position: relative;
    left: -30px;
    bottom: -62px;
    font-size: 1.3em;
    font-weight: bold;
    cursor: pointer;
}

.bio {
    color: rgba(255, 255, 255, 0.7);
    text-align: justify;
    line-height: 1.2em;
}

.top-icon {
    text-align: center;
}

.select-gender.active {
    box-shadow: 0px 2px 17px 0px #fe6c11;
    background: $accent-background;
    border: 1px solid #fe4811;

    & .gender-icon {
        color: white !important;
    }
}

.select-gender {
    border: 1px solid #ededed;
    height: 60px;
    border-radius: 8px;
    text-align: center;
    transition: 0.4s;
    cursor: pointer;

    & .gender-icon {
        color: #9e9ca5 !important;
    }
}
</style>
<style>
.cursor-slider .v-slider--horizontal {
    cursor: pointer;
}
</style>
